import { CalendarIcon, ProfileIcon, SupportIcon, Typography } from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './MyAccountMenu.module.scss';

interface MenuItemProps {
  text: string;
  href?: string;
  icon?: React.ReactNode;
  active?: boolean;
}

const MenuItem = ({ icon, text, href = '#' }: MenuItemProps) => {
  const Route = useRouter();

  return (
    <Link href={href}>
      <a className={clsx(styles.menuItem, Route.pathname === href && styles.active)}>
        {icon}
        <Typography variant="body-text-2">{text}</Typography>
      </a>
    </Link>
  );
};

const Divider = () => (
  <div className={styles.divider}>
    <hr />
  </div>
);

export const MyAccountMenu = () => {
  return (
    <nav className={styles.myAccountMenu}>
      <MenuItem icon={<ProfileIcon size={24} />} text="Perfil" href={`/perfil`} />
      <MenuItem icon={<CalendarIcon size={24} />} text="Meus Pedidos" href="/minha-conta/meus-pedidos" />
      <Divider />
      <MenuItem icon={<SupportIcon size={24} />} text="Suporte" href={`/faq`} />
    </nav>
  );
};

export default MyAccountMenu;
